:root{
  --white: #FAF9F6;
  --grey: #787878;
  --orange: #EC8A2B;
  --black: #050506;
  --darkGrey: #363636;
}

#root{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 25px;
  
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--black) ;
  color: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bg-top-triangle{
  width: 586.577px;
  height: 481.673px;
  transform: rotate(39.412deg);
  flex-shrink: 0;
  background: linear-gradient(74deg, #181717 0.08%, #050506 89.72%);
  position: fixed;
  top: -240px;
  left: 86px;
   overflow:hidden;
 z-index: -1;
}

a{
  text-decoration: none;
}

/* Components */

/* CTA Button */
.CTA-btn{
 display: flex;
padding: 5.724px 17.173px;
justify-content: center;
align-items: center;
gap: 6.869px;
border-radius: 45.795px;
background: var(--orange);
cursor: pointer;
border: none;
color: var(--black);
font-size: 16px;
font-weight: 500;
}

.CTA-btn-hover{
  opacity: 0.7;
  transition: 0.4s;
}

.CTA-btn.arrow{
  color: var(--black);
  font-size: 12px;
}

.tertiary-btn{
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--white);
  border: none;
  padding: 0;
  text-align: left;
  opacity: 0.8;
}
/* Navbar */
nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


nav a{
  color: var(--white);
  font-size: 16px;
  line-height: 130%;
}

nav a:hover{
  color: var(--orange);
  transition: 0.4s;
}

.nav-btns{
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.hamburger-div{
  display: none;
}

/* Hamburger */

@media (max-width: 767px) {

.CTA-btn{
padding: 5.724px 17.173px;
gap: 6.869px;
border-radius: 45.795px;
font-size: 14px;
}

.CTA-btn.arrow{
  font-size: 10px;
}

.tertiary-btn{
  font-size: 1.1rem;
}

  .hamburger-div{
    display: inline;
    position: relative;
  }
  .hamburger-icon{
    /* font-size: 24px; */
    width: 2rem;

    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    position: relative;
    row-gap: 7px;
    margin-bottom: 5px;
  }

  .burger{
    width: 1.5rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: var(--white);
    transform-origin: 1px;
    transition: all 0.3s linear;
    }

    .burger-1{
      background-color: var(--white);
      transform:rotate(-45deg);
      position: absolute;
    }
                
    .burger-2{
      /* position: absolute; */
      background-color: var(--white);
      transform:rotate(45deg);
      margin-top: -30px;

      /* top: 7px;
      right: 1px; */
    }
  
  .nav-btns{
    display: none;
  }

  .hamburger-btns{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:flex-start;
    position: absolute;
    top: auto;
    right: 0;
    background: var(--darkGrey);
    padding: 20px;
    row-gap: 20px;
    border-radius: 12px;
    width: 170px;
  }

  .hamburger-btn-div{
    display: flex;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .hamburger-btn-icon-container{
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hamburger-btn-icon{
    color: var(--black);
    font-size: 13px;
  }

}

/* Home  */
.home-main{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  justify-content: space-between;
  text-wrap: balance;
  margin: 60px auto 0px auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.home-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.home-content h1{
  width: 60%;
  margin: 0;
  text-align: center;
  text-shadow: 2.862px 2.862px 2.29px rgba(0, 0, 0, 0.25);
  font-size: 40px;
  font-weight: 600;
  line-height: 120%; 
  letter-spacing: -0.8px;
  text-transform: capitalize;
}

.home-content p{
  margin: 0;
  width: 43%;
  color: var(--grey);
  text-align: center;
  text-shadow: 2.862px 2.862px 2.29px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.2px;
  text-transform: capitalize;
}

.slick-slider {
  width: 90%;
  margin-top: 50px;
  height: 300px;
  position: relative;
}

.slick-track{
  display: flex !important;
  margin: auto;
  transform: none;
  align-items: baseline;
}
.slick-slide{
  background: var(--darkGrey);
  padding: 15px;
  border-radius: 50px;
  margin-inline: 5px;
  height: 350px;
  display: flex; 
  justify-content: center; 
  align-items: flex-end;
}

.slider-element{
  margin-inline: auto;
}
.slick-slide h4{
  display: none;
}

.slick-slide .slider-image{
  margin: 4px;
  background: var(--darkGrey);
  width: 100%;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center .slick-center {
  text-align: center;
}

 .center .slick-center h4{
  display: inline;
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.16px;
  text-transform: capitalize;
}

.center .slick-center .slider-image{
  /* background: var(--darkGrey); */
  background: linear-gradient(180deg, rgba(248, 249, 246, 0.96) 0%, #414141 100%);
  margin: 10px auto 0px auto;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

@media (max-width: 767px){

 .home-main{
  margin: 25px auto 0px auto;
  row-gap: 0px;
}

.home-content h1{
  width: 90%;
  font-size: 28px;
}

.home-content p{
  width: 100%;
  font-size: 14px;
}

  .slick-slider{
    width: 100%;
  margin-top: 35px;

  }
}

/* All products page */

.all-products-main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  row-gap: 30px;
  margin-top: 40px;
}

.all-products-main h2{
  color: #FAF9F6;
  text-shadow: 2.862px 2.862px 2.29px rgba(0, 0, 0, 0.25);
  font-size: 30px;
  font-weight: 500;
  line-height: 110%; 
  letter-spacing: -0.8px;
  text-transform: capitalize;

}

.all-products-container{
  display: flex;
  justify-content: flex-start;
  width: 100%;
  column-gap: 20px;
  margin-bottom: 30px;

}

.search-all-products{
  position: relative;
  width: 50%;

}

.search-input{
  width: 100%;
  height: 1em;
  padding: 1.5em;
  border-radius: 0.7em;
  margin-bottom: 10px;
}

.search-input::placeholder{
  font-size: 1rem;
  color: var(--grey);
}
/* Sidebar */



.all-products-filter-sidebar{
   display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 16%;
}

.all-products-filter-sidebar h4{
  font-size: 1.3rem;
  font-weight: 500 ;
}

.all-products-filter-sidebar .filter-btn{
  margin: 0;
  font-size: 1.3rem;
  font-weight: 500 ;
  transition: all 0.4s;
  cursor: pointer;
  padding: 0.5em 0.8em;
  background-color: var(--darkGrey);
  border-radius: 0.2em;
  border: none;
  color: var(--white);
  
}

.all-products-filter-sidebar .filter-btn:hover{
  color: var(--orange);
}



/* All products grid */
.all-products-container .all-products{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-auto-rows: 1fr;
  gap: 30px;
  width: 100%;
}

.lock{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: space-between;
}

.lock-details{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 15px;
}

.all-products-lock-main-img{
   max-width: 100%;
  display: block;
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: cover;
  background: linear-gradient(119deg, #ECECEA 0%, #BCBDBF 100%);
  border-radius: 5px;
}

.lock-image-div{
  display: flex;
  width: 100%;
  column-gap: 10px;
}

.lock-image-div-img{
  margin: 0;
  width: 16%;
  height: fit-content;
  cursor: pointer;
}

.lock-image-div img{
  background: linear-gradient(119deg, #ECECEA 0%, #BCBDBF 100%);
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: cover;
  border-radius: 3px;
}

.lock-image-div img.selected{
  border: 2px solid var(--orange);
  box-sizing: border-box;
}

.all-products-lock-name{
  font-size: 18px;
  font-weight: 500;
  line-height: 130%; 
  letter-spacing: -0.1px;
  margin: 0;
}

/* Contact Us Page */

.contact-page-main{
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  margin-top: 30px;
}

.contact-page-main h1{
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  line-height: 111%; /* 111px */
  letter-spacing: 3px;
}
.contact-page-grid{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 120px;
  justify-self: flex-start;
}

.contact-page-content{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.contact-page-content h4{
  font-size: 30px;
  font-weight: 500;
  line-height: 111%; /* 42.18px */
  text-wrap: pretty;
  color: var(--grey);
}

.contact-content-field{
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  font-size: 20px;

}

.contact-content-field span{
  color: var(--grey);
  font-weight: 500;
}

.contact-content-field h5{
  font-weight: 500;
}

.view-address-btn a{
  color: var(--white);
  opacity: 0.8;
  font-size: 20px;
  transition: all 0.4s;
  text-decoration: underline;
}

.view-address-btn a:hover{
  color: var(--orange);
}

.contact-page-grid form{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: flex-end;
  margin-top: 20px;
}

.contact-page-grid form hr{
  width: 100%;
  background: #000;
  box-shadow: 2px 2px 3px 0px rgba(255, 255, 255, 0.25);
  height: 2px;
  border: none;
}

.contact-form-input{
  width: 100%;
  border-radius: 8px;
  background: #565859;
  box-shadow: -2px -2px 0px 0px rgba(0, 0, 0, 0.25) inset, 2px 2px 0px 0px rgba(0, 0, 0, 0.25) inset;
  padding: 15px 27px;
  border: none;
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
}

.contact-form-input:focus{
  outline: none;
}

.contact-form-input::placeholder{
  color: #2A2B2C;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
}

.contact-form-textarea{
  resize: none;
  height: 100px;
}